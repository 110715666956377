'use client';

import { TFunction } from 'i18next';
import { AcceptWithdrawalOfferMutation } from '@bts-web/data-layer/server';
import {
  getPrecisionFloatValue,
  getRoundedPrecision,
  intlAssetValueFormatting,
  intlFiatValueFormatting,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { getCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';
import { SuccessModal } from '../../../../../common';
import { ModalComponent } from '@bts-web/utils-context';

export type WithdrawalSuccessModalProps = {
  currentLocale: string;
  withdrawalData: {
    asset?: NonNullable<
      AcceptWithdrawalOfferMutation['response']['acceptCryptoWithdrawal']
    >['asset'];
    assetAmount?: PrecisionFloat;
    fiatAmount?: PrecisionFloat;
  };
};

export const WithdrawalSuccessModal: ModalComponent<
  WithdrawalSuccessModalProps
> = ({ currentLocale, withdrawalData, onClose }) => {
  const { t } = useClientTranslation();

  const assetAmount = intlAssetValueFormatting(
    getPrecisionFloatValue(withdrawalData.assetAmount),
    {
      locale: currentLocale,
      fractionDigits: withdrawalData?.assetAmount?.precision,
    },
  );

  const fiatAmount = intlFiatValueFormatting(
    getRoundedPrecision(withdrawalData.fiatAmount),
    {
      locale: currentLocale,
      currency: 'EUR',
      fractionDigits: withdrawalData?.fiatAmount?.precision,
    },
  );

  const description = t('crypto_withdrawal_success_body', {
    assetAmount: `${assetAmount} ${withdrawalData.asset?.symbol}`,
    fiatAmount,
  });

  const translations = getCryptoTransferTranslations(t as TFunction);

  const continueButtonText = translations.done;

  const logoUrl = withdrawalData.asset?.logoUrl as string;

  const logoTitle = withdrawalData.asset?.name as string;

  return (
    <SuccessModal
      title={translations.cryptoWithdrawalSuccessSubtitle}
      description={description}
      continueLink={{
        label: continueButtonText,
        url: '/assets/portfolio',
      }}
      logo={{ url: logoUrl, name: logoTitle }}
      onClose={onClose}
    />
  );
};
