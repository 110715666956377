'use client';

import { FC } from 'react';
import { TransfersSectionVariants } from '../../../types';
import { TransferEntityPickerEntry } from '../TransferEntityPickerEntry/TransferEntityPickerEntry';
import {
  getPrecisionFloatValue,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { TTransfersSectionNextStepState } from '../../../utils/useTransfersNextStepState.client';
import { useTransfersAssetsListContext } from '../../TransfersAssetListContext/TransfersAssetListContext';
import { InfiniteScroll } from '@bts-web/design-system/component/infinite-scroll';
import { NoResults, SkeletonElement } from '../../../../common';
import { CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE } from '../../../../config';

export interface AssetPickerListProps {
  value?: string;
  onPick: (
    assetData: TTransfersSectionNextStepState['selectedAssetData'],
  ) => void;
  locale: typeof defaultLocale;
  variant: TransfersSectionVariants;
}

const AssetPickerList: FC<AssetPickerListProps> = ({
  onPick,
  value,
  locale,
  variant,
}) => {
  const {
    assetsListForSend,
    assetsListForDeposit,
    assetFilterSearchValue,
    canLoadMore,
    loadMore,
  } = useTransfersAssetsListContext();

  const itemList =
    variant === TransfersSectionVariants.Deposit
      ? assetsListForDeposit
      : assetsListForSend;

  const callOnPickAndCheckIfWithdrawalAllowed = (
    assetData: TTransfersSectionNextStepState['selectedAssetData'],
  ) => {
    onPick(assetData);
  };

  if (!itemList || itemList.length === 0) {
    return <NoResults searchTerm={assetFilterSearchValue} />;
  }

  return (
    <ul aria-label={`asset selection list`}>
      <InfiniteScroll
        hasMore={canLoadMore}
        itemsPerPage={CRYPTO_TRANSFERS_ASSET_ITEMS_PER_PAGE}
        fetchMore={loadMore}
        skeleton={<SkeletonElement width="100%" height="60px" />}
        items={itemList}
        renderItemComponent={(item) => (
          <TransferEntityPickerEntry
            withinList
            title={item.name ?? ''}
            subTitle={item.symbol}
            type="entryWithActiveState"
            active={value === item.id}
            endInfoMain={
              variant === TransfersSectionVariants.Deposit
                ? intlFiatValueFormatting(item.price?.value as string, {
                    locale: locale,
                    notation: 'standard',
                    currency: 'EUR',
                    fractionDigits: item.price?.precision,
                  })
                : intlFiatValueFormatting(
                    item.portfolio?.fiatBalance?.value as string,
                    {
                      locale: locale,
                      notation: 'standard',
                      currency: 'EUR',
                      fractionDigits: item.portfolio?.fiatBalance?.precision,
                    },
                  )
            }
            endInfoSub={
              variant === TransfersSectionVariants.Send
                ? getPrecisionFloatValue(item.portfolio?.assetBalance)
                : undefined
            }
            imageUrl={item.logoUrl ?? undefined}
            onPick={() =>
              callOnPickAndCheckIfWithdrawalAllowed({
                name: item.name as string,
                id: item.id,
                symbol: item.symbol as string,
                logoUrl: item.logoUrl as string,
                price: item.price,
                portfolio: {
                  assetBalance: item.portfolio?.assetBalance,
                  fiatBalance: item.portfolio?.fiatBalance,
                },
              })
            }
            key={item.id}
          />
        )}
      />
    </ul>
  );
};

export { AssetPickerList };
